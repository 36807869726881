import React from 'react'
import hero from '../images/hero.jpg'
import { motion } from 'framer-motion'

const Hero = () => {
  return (
    <div className='object-cover w-full mb-24 overflow-hidden bg-top glass'>
      <div
        style={{ backgroundImage: `url(${hero})`, backgroundPositionY: '15%', backgroundPositionX: 'center' }}
        className='flex items-center justify-center w-full h-full p-4 bg-black bg-cover md:p-8 md:py-32 md:justify-start'
      >
        <div className='flex flex-col items-start gap-6 p-4 bg-opacity-40 md:bg-opacity-60 bg-slate-800 glass'>
          <h1 className='w-full text-4xl font-bold text-white md:w-96'>
            I help people build the happiest relationships of their lives
          </h1>
          <motion.a
            href='#newsletter'
            className='flex items-center gap-2 px-4 py-4 text-sm uppercase bg-blue-600 rounded-md shadow-md'
            whileHover={{ scale: 1.05, transition: { duration: 0.25, type: 'spring' } }}
          >
            Free breakup guide
          </motion.a>
        </div>
      </div>
    </div>
  )
}

export default Hero
