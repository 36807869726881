import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Testimonial from './testimonials/Testimonial'

const testimonies = [
  {
    text: 'Cole is amazing! He helped guide me through a difficult breakup with his knowledge, kindness and patience. He helped me learn to love myself and has inspired me to be the best version of myself. I am forever grateful to you, Cole. The world is a better place because you’re in it!',
    name: 'Elizabeth A.',
  },
  {
    text: 'A couple of months ago when I was broken up with, I was experiencing intense emotional pain. At the peak of this pain, I came across Cole and decided to sign up for a few coaching sessions. Cole helped shift my mindset to a place where unnecessary pain was eliminated. Thanks to him, I was able to move on and rediscover my happiness as an individual.',
    name: 'Ian R.',
  },
  {
    text: 'Cole was super helpful and understanding of my situation. He’s a great listener and extremely easy to talk to. He was really helpful with coming up with a plan to move forward with getting my ex back and gave me valuable resources to do so.',
    name: 'Carlos Z.',
  },
  {
    text: 'Coach Cole Zesiger helped me a lot to get my ex back. For me one of the best coaches on TikTok. He even supported me on instagram! Great guy and coach!',
    name: 'Ivan'
  },
  {
    text: 'I just wanted to say that cole is the best! Not only does he give you practical help on how to repair your relationship but he genuinely cares about you and takes time to get to know you and figure out a solution to your situation. He\’s also helped me realize that the first step to getting my ex back is focusing on myself and trying to do things that make me happy! He\’s literally like the nicest guy I\’ve ever met and I hope in a couple months or years I\’ll be able to give a testimonial for him and other ppl so he can use me as an example haha.',
    name: 'Sophia Z'
  }
]

const Testimonials = () => {
  return (
    <div className='flex flex-col gap-12 md:flex-row'>
      <div className='w-full md:w-96'>
        <h3 className='mb-8 text-5xl font-bold'>
          See what others have to say about Cole
        </h3>
        <p className='text-base md:text-lg'>
          Read from those who have had personal coaching from Cole. See how they
          were able to overcome the challenges they faced and find healing!
        </p>
      </div>
      <div className='flex-1 overflow-hidden'>
        <Carousel
          autoPlay={true}
          centerMode={true}
          infiniteLoop={true}
          emulateTouch={true}
          interval={5000}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
        >
          {testimonies.map((testimony, index) => (
            <Testimonial key={index} text={testimony.text} name={testimony.name} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Testimonials
