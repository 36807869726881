import React from 'react'
import Section from './Section'
import { motion } from 'framer-motion'
import { InlineWidget } from 'react-calendly'
import Newsletter from './Newsletter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faCalendar,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'
import Testimonials from './Testimonials'

const Body = () => {
  const textAnim = {
    hidden: {
      opacity: 0,
      x: -10,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <>
      <main>
        <Section title="Let's Talk">
          <div
            id='lets-talk'
            className='flex flex-col justify-between gap-12 mb-12 md:mb-24 md:flex-row'
          >
            {/* <div className='flex-1'>
              <div className='flex-1 p-2 glass'>
                <h2 className='mb-4 text-xl font-bold text-center'>
                  Breakup <br className='hidden md:block' /> Blueprint
                </h2>
                <hr className='mx-2 mb-6 border-2 border-purple-400' />
                <div className='flex justify-center'>
                  <FontAwesomeIcon
                    icon={faPeopleGroup}
                    className='mb-2 text-purple-300 text-7xl'
                  />
                </div>
                <p className='p-2'>
                  This option is for those who are serious about moving on after
                  a breakup or getting their ex back. This is a lifetime access
                  pass to my Breakup Blueprint, a private community and coaching
                  platform where you will be able to work with me as well as all
                  of the other members one on one allowing you to get
                  personalized feedback and help in a way that is not possible
                  through a phone call or email. <br/><br/> This is a long term fix so that
                  when you do get your ex back or when you do get in another
                  relationship, you don't make the same mistakes you made
                  before. <br/><br/> We are currently looking for 10 early access beta
                  clients that will have a discount on the $2,000 USD price
                  point (discount available on call). To learn more about how to
                  get started, book a call below.
                </p>
              </div>
            </div> */}
            <div className='flex-1'>
              <div className='flex flex-col gap-4 md:flex-row'>
                <div className='flex-1 p-2 glass'>
                  <h2 className='mb-4 text-xl font-bold text-center'>
                    Email <br className='hidden md:block' /> Coaching
                  </h2>
                  <hr className='mx-2 mb-4 border-2 border-blue-400' />
                  <div className='flex justify-center'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='mb-2 text-blue-300 text-7xl'
                    />
                  </div>
                  <p className='p-2'>
                    Send me an email with your story. I will give my best advice
                    to help create a personalized plan for you to get your ex
                    back or save your current relationship!
                  </p>
                </div>
                <div className='flex-1 p-2 glass'>
                  <h2 className='mb-4 text-xl font-bold text-center'>
                    60 Minute Coaching Call
                  </h2>
                  <hr className='mx-2 mb-6 border-2 border-orange-600' />
                  <div className='flex justify-center'>
                    <FontAwesomeIcon
                      icon={faPhone}
                      className='mb-2 text-orange-300 text-7xl'
                    />
                  </div>
                  <p className='p-2'>
                    Speak with me directly to make a customized plan to get your
                    ex back or save your relationship! Because talking is more
                    personal, I can often understand the situation better than
                    is possible through email.
                  </p>
                </div>
                <div className='flex-1 p-2 glass'>
                  <h2 className='mb-4 text-xl font-bold text-center'>
                    1 Month <br className='hidden md:block' /> Coaching
                  </h2>
                  <hr className='mx-2 mb-6 border-2 border-green-400' />
                  <div className='flex justify-center'>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className='mb-2 text-green-300 text-7xl'
                    />
                  </div>
                  <p className='p-2'>
                    Need consistent help? Let's meet once a week for a 30 minute
                    call for four weeks. We can work together to implement your
                    plan to get your ex back and I will support you every step
                    of the way.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-12 md:flex-row'>
            <div className='flex-1'>
              <h3 className='mb-8 text-5xl font-bold'>Talk with Cole</h3>
              <div className='overflow-hidden glass'>
                <InlineWidget
                  url='https://calendly.com/colezesiger?background_color=292f3c&text_color=ffffff&primary_color=3b82f6&hide_landing_page_details=1&hide_gdpr_banner=1'
                  styles={{ width: '100%', height: '400px' }}
                />
              </div>
            </div>
            <div className='flex-1'>
              <h3 className='mb-8 text-5xl font-bold'>About Me</h3>
              <motion.p
                className='text-base md:text-lg'
                variants={textAnim}
                initial='hidden'
                whileInView='visible'
                transition={{ delay: 0.5 }}
                viewport={{ once: true }}
              >
                I got married when I was 20 years old and I loved it. Always
                having someone you can turn to and have fun with is an amazing
                thing. <br />
                <br /> One day, after we had been married for almost three
                years, my ex wife told me we were getting a divorce. We hadn’t
                communicated well and it came as a complete surprise to me.
                Within a couple of months, we were divorced. That pain and the
                pain of other relationships caused me to go on a journey of self
                discovery. <br />
                <br /> I read and continue to read every book I can on
                communication and success in relationships. I now make videos
                and do coaching calls to help others avoid the pain I have gone
                through!
              </motion.p>
            </div>
          </div>
        </Section>
        <Section title='Testimonials'>
          <Testimonials />
        </Section>
        <Section title='Newsletter'>
          <div
            id='newsletter'
            className='flex flex-col gap-8 md:flex-row md:gap-32'
          >
            <div className='flex items-center justify-center flex-1 p-4 glass'>
              <h2 className='text-2xl text-center'>
                Sign up now to receive a FREE copy of
                <span className='block my-5 text-3xl font-bold text-blue-300 md:text-5xl'>
                  "5 Stages Your Ex Goes Through After a Break Up"
                </span>
                and receive tips and tricks from Cole!
              </h2>
            </div>
            <div className='flex-1'>
              <Newsletter />
            </div>
          </div>
        </Section>
      </main>
      <footer className='flex justify-center my-8 text-gray-500'>
        <p>Copyright 2022 - Cole Zesiger</p>
      </footer>
    </>
  )
}

export default Body
