import React from 'react'

const Testimonial = ({ text, name }) => {
  return (
    <div className='relative flex flex-col justify-between gap-2 p-4 pt-10 mx-2 my-4 overflow-hidden text-gray-800 border-t-4 border-blue-300 rounded-lg shadow-lg w-100 md:p-8 md:mx-4 md:h-96 bg-blue-50'>
      <div class='flex-1 flex flex-col justify-center h-100'>
        <p className='text-base font-bold text-left md:text-lg'>
          {text}
        </p>
      </div>
      <p className='text-xl font-light text-gray-500'>{name}</p>
      <div className='absolute top-0 right-0 w-10 h-10 bg-blue-200 rounded-bl-lg'></div>
      <div className='absolute w-16 h-16 rotate-45 bg-blue-100 -right-10 -top-8'></div>
    </div>
  )
}

export default Testimonial
