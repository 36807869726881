import { MotionConfig } from 'framer-motion'
import { Helmet } from 'react-helmet'

import React from 'react'
import Body from '../components/Body'
import Header from '../components/Header'
import Hero from '../components/Hero'

const index = () => {
  return (
    <MotionConfig reducedMotion='user'>
      <Helmet>
        <title>Cole Zesiger - Relationship Advisor</title>
        <meta
          name='description'
          content='Cole Zesiger helps couples create happier and deeper relationships.'
        />
      </Helmet>
      <div className='pt-12'>
        <div className='container px-8 mx-auto'>
          <Header />
          <Hero />
          <Body />
        </div>
      </div>
    </MotionConfig>
  )
}

export default index
