import React from 'react'

const Newsletter = () => {
  return (
    <div>
      <div id='mc_embed_signup'>
        <form
          action='https://colezesiger.us14.list-manage.com/subscribe/post?u=12bb2cc4e00a14f380abc727a&amp;id=1dd3fd5fa4'
          method='post'
          id='mc-embedded-subscribe-form'
          name='mc-embedded-subscribe-htmlForm'
          className='validate'
          target='_blank'
          noValidate
        >
          <div id='mc_embed_signup_scroll' className='flex flex-col gap-4'>
            <div className='text-xs text-gray-500 indicates-required'>
              <span className='asterisk'>*</span> indicates required
            </div>
            <div className='flex flex-col gap-1 mc-field-group'>
              <label className='text-sm font-bold text-gray-300 uppercase' htmlFor='mce-EMAIL'>
                Email Address <span className='asterisk'>*</span>
              </label>
              <input
                type='email'
                defaultValue=''
                name='EMAIL'
                className='px-1 py-2 required email glass'
                id='mce-EMAIL'
              />
            </div>
            <div className='flex flex-col gap-1 mc-field-group'>
              <label className='text-sm font-bold text-gray-300 uppercase' htmlFor='mce-FNAME'>First Name </label>
              <input
                type='text'
                defaultValue=''
                name='FNAME'
                className='px-1 py-2 glass'
                id='mce-FNAME'
              />
            </div>
            <div className='flex flex-col gap-1 mc-field-group'>
              <label className='text-sm font-bold text-gray-300 uppercase' htmlFor='mce-LNAME'>Last Name </label>
              <input
                type='text'
                defaultValue=''
                name='LNAME'
                className='px-1 py-2 glass'
                id='mce-LNAME'
              />
            </div>
            <div id='mce-responses' className='clear foot'>
              <div
                className='response'
                id='mce-error-response'
                style={{ display: 'none' }}
              ></div>
              <div
                className='response'
                id='mce-success-response'
                style={{ display: 'none' }}
              ></div>
            </div>
            <div style={{ position: 'absolute', left: '-5000px'}} aria-hidden='true'>
              <input
                type='text'
                name='b_12bb2cc4e00a14f380abc727a_1dd3fd5fa4'
                tabIndex='-1'
                defaultValue=''
              />
            </div>
            <div className='flex justify-center optionalParent'>
              <div className='clear foot'>
                <input
                  type='submit'
                  value='Get PDF and Subscribe'
                  name='subscribe'
                  id='mc-embedded-subscribe'
                  className='flex items-center gap-2 px-4 py-4 text-sm uppercase transition-all bg-blue-600 rounded-md shadow-md cursor-pointer button hover:scale-105'
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Newsletter
