import React from 'react'
import { motion } from 'framer-motion'

const Section = ({ title, children }) => {

  const lineAnim = {
    hidden: {
      width: '0px',
    },
    visible: {
      width: '52px',
    },
  }

  const sectionAnim = {
    hidden: {
      opacity: 0,
      y: -10,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <section>
      <div className='mb-8'>
        <h2 className="subheader">
          {title}
        </h2>
        <motion.div 
          className='h-1 bg-blue-300 rounded-full'
          variants={lineAnim}       
          initial='hidden' 
          whileInView='visible'
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
        >
        </motion.div>
      </div>
      <motion.div
        className="p-8 mb-24 md:p-12 glass"
        variants={sectionAnim}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        transition={{ delay: 0.1 }}
      >
        { children }
      </motion.div>
    </section>
  )
}

export default Section
